if (!window.lmpost) {
  window.lmpost = {};
}

const baseApiUrl = "*|BASE_API_URL|*";

if (!lmpost.urls) lmpost.urls = {};
if (!lmpost.urls.portalUrl) lmpost.urls.portalUrl = `${baseApiUrl}/cserv`;
if (!lmpost.urls.apiUrl)
  lmpost.urls.apiUrl = "https://www.loanmatchingservice.com/";
if (!lmpost.urls.submitUrl) lmpost.urls.submitUrl = "post/live.aspx";
if (!lmpost.urls.supportUrl)
  lmpost.urls.supportUrl = "https://www.loanmatchingservice.com/misc/";
if (!lmpost.urls.hitUrl)
  lmpost.urls.hitUrl = "https://www.sparning.com/hit/hit.core.js";

lmpost.registerHit = function () {
  const script = document.createElement("script");
  script.setAttribute("type", "text/javascript");
  script.setAttribute("src", lmpost.urls.hitUrl);
  document.getElementsByTagName("head")[0].appendChild(script);
};

lmpost.actionUrl = function (action, params) {
  const url =
    window.lmpost.urls.apiUrl + "/misc/?responsetype=json&action=" + action;
  const proto = url.match(/^(http|https):(\/)+/);
  const si = proto ? proto[0].length : 0;
  let ps = "";

  params = params || {};
  params.uts = new Date().getTime();
  params.uid = lmpost.options.hituid;

  for (let p in params) {
    const v = params[p];
    if (v) ps += "&" + p + "=" + escape(params[p]);
  }

  return (
    (proto ? proto[0] : "") + url.substr(si).replace(/(\/){2,}/g, "/") + ps
  );
};

lmpost.makeUrl = function (baseUrl) {
  if (!baseUrl.match(/^(http|https):/)) {
    return lmpost.urls.apiUrl + baseUrl;
  } else {
    return baseUrl;
  }
};

lmpost.scriptUrl = function (scriptPath) {
  const url = lmpost.options.domain + scriptPath;
  const proto = url.match(/^(http|https):(\/)+/);
  const si = proto ? proto[0].length : 0;

  return (proto ? proto[0] : "") + url.substr(si).replace(/(\/){2,}/g, "/");
};

lmpost.calculateAPR = function (amount, totalFee, numberOfDays) {
  let apr = 0;
  const inputReady =
    isNumeric(amount) &&
    isNumeric(totalFee) &&
    isNumeric(numberOfDays) &&
    amount > 0 &&
    numberOfDays > 0;

  if (!inputReady) {
    return -1;
  }

  apr = 36500 * (totalFee / amount / numberOfDays);

  return apr;
};

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

// Copy query fields data to small forms.

const smallForms = $("form.apply-now");
const isUsualSmallForm = smallForms.find("#SSN").length === 0;
const ctrs = isUsualSmallForm
  ? ["ZipCode", "Email"]
  : ["RequestedAmount", "YOB", "ZipCode", "SSN"];

smallForms.each(function () {
  const el = $(this);

  for (let c in ctrs) {
    const ctr = el.find("#" + ctrs[c]);
    const par = getParameterByName(ctrs[c]);

    par && ctr && ctr.length && ctr.val(par);
  }
});

// Init redirection from main form on Press Enter.
$("form.apply-now input").keypress(function (e) {
  if (e.which === 13 && $(this).val().length !== 0) {
    $(".btn-cash")[0].click();
  }
});

// Init redirection from main form.
$("form#ApplyNow a.btn-cash").on("click", function () {
  const elem = $(this);
  const form = elem.closest("form");

  if (form.validate) {
    form.validate({
      errorPlacement: function (error, element) {
      }
    });

    if (!form.valid()) {
      return false;
    }
  }

  const queryParams = form.find(":not(.b2c-dont-send)").serialize();
  const targetUrl = lmpost.urls.supportUrl;
  const href = elem.attr("href") + "";
  const pos = href.indexOf("?");
  const ltid = href.toLowerCase().indexOf("title") > 0 ? 18 : 9;
  const underpop = elem.attr("target") === "_blank";
  const target =
    (pos > 0 ? href.substr(0, pos) : href) +
    "?" +
    (queryParams || "").replace(/\+/g, "%20");
  const ajaxopt = {
    type: "get",
    dataType: "jsonp",
    timeout: 5000
  };
  const transferFunc = function () {
    if (!underpop) {
      window.location.href = target;
    } else {
      const zip = $("#ZipCode").val();
      window.setTimeout(function () {
        window.location.href =
          "/Home/Loan-Offers" + (zip === "" ? "" : "?zipcode=" + zip);
      }, 5000);
    }
  };
  const returningCustomerFunc = function (data) {
    if (data.RID) {
      lmpost.RID = data.RID;
    }

    transferFunc();
  };
  let callback = transferFunc;
  if (isUsualSmallForm) {
    ajaxopt.url =
      targetUrl +
      "?action=subscribe&responsetype=json&uid=" +
      lmpost.options.hituid +
      "&LeadTypeID=" +
      ltid +
      "&PathID=1&" +
      queryParams;
  } else {
    ajaxopt.url =
      targetUrl +
      "?action=leadreturn&responsetype=json&uid=" +
      lmpost.options.hituid +
      "&leadtypeid=" +
      ltid +
      "&" +
      queryParams;
    callback = returningCustomerFunc;
  }

  $.ajax(ajaxopt)
    .done(callback)
    .fail(callback);

  if (underpop) {
    elem.attr("href", target);
    return true;
  } else {
    return false;
  }
});

const handlers = lmpost.onGeneralStart;
if (handlers && handlers.length) {
  for (let i in handlers) {
    handlers[i]();
  }
}

function loadExitFrame() {
  if (!lmpost.options.exitBlockerUrl) {
    return;
  }

  let url = lmpost.options.exitBlockerUrl;
  const subId = window.lmpost.options.campaignid;
  const zip = $("#ZipCode").val();
  const protocol = document.location.protocol;

  if (!url) {
    return;
  }

  if (url && url.indexOf("{AffID}") > 0) {
    url = url.replace("{AffID}", !subId ? "" : subId);
  }

  if (url && url.indexOf("{ZipCode}") > 0) {
    url = url.replace("{ZipCode}", !zip ? "" : zip);
  }

  if (protocol === "https:") {
    url = url.replace("http:", protocol);
  }

  if ($("#exitFrame").length === 0) {
    const unloadFunc = function () {
      $(window).off("beforeunload");

      $("#exitFrame").show();
      return "Press CANCEL to view options!";
    };

    $(window).on("click", function (e) {
      const el = e.target;

      if (el.href) {
        $(window).off("beforeunload");
        setTimeout(function () {
          $(window).on("beforeunload", unloadFunc);
        }, 1000);
      }

      return true;
    });

    $("body").append(
      '<iframe id="exitFrame" src="' +
      url +
      '" style="overflow:hidden; display:none; top: 0; width: 100%; height: 100%; left: 0; z-index: 10001; position: fixed; background:#fff;"/>'
    );
    $(window).on("beforeunload", unloadFunc);
  }
}

function getParameterByName(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regexS = "[\\?&]" + name + "=([^&#]*)";
  const regex = new RegExp(regexS, "i");
  const results = regex.exec(window.location.search);
  if (results !== null) {
    return decodeURIComponent(results[1].replace(/\+/g, " "));
  }
  return null;
}

(function init() {
  let protocol = document.location.protocol;

  if (protocol === "file:") {
    protocol = "http:";
  }

  if (!getParameterByName("ZipCode")) {
    loadExitFrame();
  }

  $("div.form-container").on("change", "#ZipCode", function () {
    $("#exitFrame").remove();
    loadExitFrame();
  });

  const origSuccess = lmpost.options.onSuccess;
  lmpost.options.onSuccess = function (data, defaultHandler) {
    $(window).off("beforeunload");

    if (origSuccess) {
      origSuccess(data, defaultHandler);
    } else {
      defaultHandler && defaultHandler(data);
    }
  };

  return false;
})();
